.base {
  overflow-x: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.loading {
  position: absolute;
  margin: 0 auto;
  height: 250px;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
  min-width: 100%;
}

.tr {
  height: 60px;
}

.td,
.th {
  padding: 0 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th {
  text-align: left;
  position: relative;
}

.thead > tr {
  border-radius: 9px;
}

.tbody > tr {
  border-bottom: 1px solid #c4c4c4;
}

.tbody > tr:first-child {
  border-top: 1px solid #c4c4c4;
}

.sort_icon {
  position: absolute;
  top: 17px;
  right: 5px;
  color: #c4c4c4;
}

.no_record_tr {
  > td {
    padding: 15px 20px;
    color: #c4c4c4;
  }
}

.loading_icon_tr {
  padding: 15px 20px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.muiField {
  > div {
    border-radius: 50px;
  }
}

.page_count_field {
  width: 80px;
  margin-right: 10px !important;
  > div {
    border-radius: 5px;
  }
}

.pagination_button {
  background-color: #007373;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.09);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;

  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  cursor: pointer;
}

.pagination {
  display: flex;
  height: 40px;
  gap: 5px;
}
