.base {
  display: grid;
  grid-template-columns: 250px 5fr;
}

@media screen and (max-width: 768px) {
  .base {
    grid-template-columns: 1fr;
  }
}

.logo {
  width: 120px;
  margin: 20px;
}

.menu_base {
  z-index: 10;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 15px 0 #00000014;
  transition: all 300ms ease-out;
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .menu_base {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: white;
    margin-left: -250px;
  }
}

.section_wrapper {
  overflow-y: scroll;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .menu_open {
    margin-left: 0;
  }
}

.menu_nav_top {
  display: flex;
  justify-content: center;
}

.menu_nav_top img {
  filter: grayscale(100%);
}

.menu_close_button {
  display: none;
}

@media screen and (max-width: 768px) {
  .menu_close_button {
    display: flex;
    width: 30px;
    height: 30px;
    margin: 20px;
    color: #8d8d8d;
    align-self: flex-end;
  }
}

.menu_section {
  padding: 20px 40px;
}

.menu_item_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}

.menu_item_wrapper > p {
  font-weight: bold;
  font-size: 20px;
}

.menu_item_wrapper > svg {
  color: #989898;
}

.menu_item_active {
  color: #007373 !important;
}

.collapse_item_wrapper {
  display: flex;
  align-items: center;
  color: #989898;
  cursor: pointer;
  margin: 20px 0;
  text-decoration: none;
}

.collapse_item_wrapper > svg {
  margin: 0 20px;
}

.collapse_item_wrapper > p {
  font-weight: bold;
}

.nav_bar {
  box-shadow: 13px 6px 15px 0 #00000014;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_bar > svg {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin: 30px 20px;
}

@media screen and (max-width: 768px) {
  .nav_bar > svg {
    opacity: 1;
    width: 30px;
    height: 30px;
    color: #989898;
  }
}

.nav_title {
  margin-left: 30px;
  font-size: 30px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .nav_title {
    margin-left: 70px;
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  .nav_title {
    font-size: 18px;
  }
}

.nav_actions {
  display: flex;
  align-items: center;
}

.nav_actions > svg {
  margin-right: 20px;
}

.avatar_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.avatar_wrapper > div {
  color: #007373;
}

.content_base {
  height: 100vh;
  display: grid;
  grid-template-rows: 90px auto;
}

.content {
  overflow: scroll;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
