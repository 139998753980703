.base {
  padding: 20px;
}

@media print {
  .base {
    padding: 0;
  }

  .printSection {
    display: none;
  }
}
